export default {
  msg: {
    lang: '中文',
    multiple_page: 'Hi language - 多文本翻译',
    single_page: 'Hi language - 单文本翻译',
    list_page: 'Hi language - 文本翻译语言列表',
    multiple_title: '多文本翻译',
    single_title: '单文本翻译',
    list_title: '文本翻译语言列表'
  }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/docs',
    component: () => import('../views/docs.vue'),
    children: [
      {
        path: '',
        redirect: 'api/text/multiple'
      },
      {
        path: 'api/text/single',
        name: 'singleText',
        component: () => import('../components/singleText.vue'),
        meta: {
          title: i18n.t('msg.single_page')
        }
      },
      {
        path: 'api/text/multiple',
        name: 'multipleText',
        component: () => import('../components/multipleText.vue'),
        meta: {
          title: i18n.t('msg.multiple_page')
        }
      },
      {
        path: 'api/text/languageList',
        name: 'languageList',
        component: () => import('../components/languageList.vue'),
        meta: {
          title: i18n.t('msg.list_page')
        }
      }
    ]
  },
  {
    path:'/test',
    component:()=>import('../views/test.vue'),
    meta: {
      title: i18n.t('msg.single_page')
    }
  },
  { path: '*', redirect: '/docs/api/text/multiple' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

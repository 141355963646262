import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'amfe-flexible'
import 'element-ui/lib/theme-chalk/index.css'
import i18n from './i18n'

Vue.config.productionTip = false
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value) // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
})

router.beforeEach((to, from, next) => {
  console.log(to, from, next)
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
    next()
  }
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default {
  msg: {
    lang: 'English',
    multiple_page: 'Hi language - Multiple text translation',
    single_page: 'Hi language - Single text translation',
    list_page: 'Hi language - Text translation language list',
    multiple_title: 'Multiple text translation',
    single_title: 'Single text translation',
    list_title: 'Text translation language list'
  }
}
